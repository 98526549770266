import { MenuItem } from 'primeng/api';
import { AccessRule } from '@camino-solutions/core/access-rule';
import { InjectionToken } from '@angular/core';

export interface AppMenuItem<T extends AccessRule = AccessRule> extends MenuItem {
  accessRule?: T;
  items?: AppMenuItem<T>[];
}

export const MENU_TOKEN = new InjectionToken<AppMenuItem[]>('MENU_TOKEN');
